<template>
  <main
    class="flex w-full md:w-11/12 md:mx-auto md:bg-white rounded-xl overflow-hidden pt-4 md:border gap-4 md:gap-0 border-dark-100 md:p-0 flex-col md:flex-row items-start"
  >
    <div
      class="bg-white md:bg-transparent rounded-2xl md:rounded-none grid grid-cols-2 md:grid-cols-1 p-2 md:p-0 md:py-8 w-full md:w-60 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        :key="i"
        @click="switchView(i)"
        :class="[
          btn.active
            ? ' text-primary bg-highlight font-semibold shadow-sm '
            : 'text-dark-800',
          i == 0 ? 'rounded-l-2xl' : '',
          i == pages.length - 1 ? 'rounded-r-2xl' : '',
        ]"
        class="relative col-span-1 w-full capitalize md:rounded-none border md:border-0 md:border-t border-dark-100 text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap"
      >
        {{ btn.title }}
        <div
          v-if="btn.active"
          style="width: 3px"
          class="hidden md:block absolute right-0 top-0 h-full rounded-l-sm bg-primary"
        ></div>
      </button>
    </div>
    <div
      class="relative shadow-md md:shadow-none rounded-2xl md:rounded-none w-full md:w-11/12 text-sm md:border-l border-dark-100 h-auto bg-white"
    >
      <div
        class="bg-white shadow-md w-full pt-4 pb-5 md:px-auto flex justify-center"
      >
        <div
          style="border-radius: 40px"
          class="flex flex-col md:flex-row bg-newAsh shadow-sm cursor-pointer md:items-center w-11/12 mt-3 py-4 px-4 gap-4"
        >
          <img
            class="hidden md:block w-10"
            src="@/assets/icons/automate-icon.svg"
            alt=""
          />
          <div class="text-left">
            <h4 class="text-dark-800 font-bold mb-1 text-lg">
              Payroll Automation
            </h4>
            <h5 class="font-medium text-sm text-dark-800">
              Automate your payroll payment (Net pay, HMO, <br />
              PAYE, Pension, NHF, NSITF, ITF) and save more time
            </h5>
          </div>

          <div class="flex items-center gap-3">
            <div
              style="
                background: linear-gradient(
                  to right,
                  rgba(170, 170, 170, 0.08),
                  rgba(17, 69, 59, 0.08)
                );
              "
              class="px-3 py-3 rounded-full w-full md:w-28 flex items-center gap-3"
            >
              <!-- <InputSwitch
                v-model="checkedBool"
                :class="
                  checkedBool == true
                    ? 'bg-activeGreen border border-primary'
                    : 'bg-inactiveState border border-inactiveBorder'
                "
                class="rounded-full shadow-xs"
              /> -->

              <div class="w-12">
                <SwitchInput
                  @switch="switchAsync($event)"
                  :boolValue="checkedBool"
                  class="w-24"
                />
              </div>

              <p class="text-primary font-bold text-sm">
                {{ checkedBool ? "ON" : "OFF" }}
              </p>
            </div>

            <div
              @click="setFrequency = true"
              style="background: rgba(17, 69, 59, 0.08)"
              class="px-3 py-3 rounded-full w-full md:w-36 flex items-center gap-3"
            >
              <div
                class="w-7 h-7 bg-white flex flex-col items-center justify-center rounded-full"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75V13.25M13.25 7L0.75 7"
                    stroke="#0F172A"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p class="text-primary text-sm capitalize font-bold">
                create new
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full items-center gap-4 mt-6 pb-20">
        <div
          class="w-11/12"
          v-for="pay in automations"
          :key="pay._id"
          @mouseenter="
            () => {
              show = !show;
              activeAuto = pay._id;
            }
          "
          @mouseleave="
            () => {
              show = false;
              activeAuto = '';
            }
          "
        >
          <div
            class="flex justify-between items-start rounded-b-none rounded-2xl px-3 py-2 w-full shadow-sm relative bg-mint"
          >
            <div
              :style="{
                backgroundColor: pay.paused
                  ? 'rgba(239, 69, 69, 0.08)'
                  : 'rgba(33, 150, 83, 0.08)',
              }"
              style="border-radius: 8px"
              class="py-2 px-4"
            >
              <h2
                class="w-full md:font-medium text-sm text-left"
                :class="pay.paused ? 'text-error' : 'text-success'"
              >
                {{ !pay.paused ? "Active" : "Inactive" }}
              </h2>
            </div>
            <div
              v-show="show && pay._id === activeAuto"
              class="flex items-center gap-3"
            >
              <button
                v-for="act in ['edit', 'pause', 'resume', 'delete']"
                :key="act"
                style="
                  background-color: rgba(17, 69, 59, 0.08);
                  padding: 8px 8px;
                  border-radius: 8px;
                "
                :class="getClass(act, pay)"
                class="flex items-center gap-2 text-primary font-medium focus:outline-none capitalize"
                @click="updateArgs(pay, act)"
              >
                <div
                  class="w-5 h-5 flex rounded-full bg-white items-center justify-center"
                >
                  <img :src="icons[act]" alt="" />
                </div>
                <span>{{ act }}</span>
              </button>
            </div>
          </div>
          <div
            style="background-color: #f8f8f8"
            class="border-r border-l border-b border-outlineGray flex justify-between items-start w-full rounded-t-none pt-5 pb-3 px-3 rounded-2xl"
          >
            <div class="flex items-center w-full gap-4">
              <div
                class="flex items-center justify-center w-12 h-12 rounded-full bg-white"
              >
                <img src="@/assets/icons/calendar-dark.svg" alt="" />
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-sm text-dark-200 capitalize">date</span>
                <h4 class="text-dark-200 font-medium">
                  {{ getDateFromDay(pay.payrollDate).dateString }},
                  <span class="font-bold">{{
                    getDateFromDay(pay.payrollDate).formattedDate
                  }}</span>
                </h4>
              </div>
            </div>

            <div
              class="flex items-center w-full gap-4 border-l pl-5 border-outlineGray"
            >
              <div
                class="flex items-center justify-center w-12 h-12 rounded-full bg-white"
              >
                <img src="@/assets/icons/cube.svg" alt="" />
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-sm text-dark-200 capitalize">service</span>
                <h4 class="text-dark-200 font-medium">
                  {{
                    getPaymentOptions(pay.paymentOptions)
                      .toString()
                      .replace("[", "")
                      .replace("]", "")
                      .replace(/,(?=[^\s])/g, ", ")
                  }}
                </h4>
                <!-- <p class="flex-none text-center text-grey text-xs">
                {{
                  getPaymentOptions(pay.paymentOptions)
                    .toString()
                    .replace("[", "")
                    .replace("]", "")
                    .replace(/,(?=[^\s])/g, ", ")
                }}.
              </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        @click="customizePayroll = true"
        class="flex bg-successStatus shadow-md rounded items-center cursor-pointer w-full rounded-xl mt-3 py-5 px-4 gap-6"
      >
        <img class="w-12" src="@/assets/icons/customize-icon.svg" alt="" />
        <h5 class="font-medium text-xl text-white">Customize Payroll</h5>
      </div> -->
    </div>

    <easiModal
      v-if="setFrequency === true"
      @close="setFrequency = false"
      pixelRounded="40px"
      :isBorder="false"
    >
      <template v-slot:header>
        <h1 class="capitalize md:text-2xl text-xl font-medium text-headerText">
          Please select payroll frequency
        </h1>
      </template>

      <div class="px-3 md:px-6">
        <div
          class="text-center bg-newAsh text-dark-800 text-xs md:text-sm font-bold mt-2 mb-4 bg-yellow p-3 rounded-lg"
        >
          You’ll be required to complete a 2nd verification step
        </div>

        <div class="flex flex-col w-full gap-4">
          <div
            v-for="(freq, i) in frequency"
            @click="args.timeline = freq.value"
            :key="i"
            :class="
              args.timeline === freq.value
                ? 'border border-primary bg-newLimeGreen'
                : 'border border-outlineGray'
            "
            class="relative text-sm shadow-md cursor-pointer flex gap-x-5 font-bold items-center gap-2 rounded-md md:rounded-xl py-4 px-6 w-full"
          >
            <span class="">
              <img
                v-if="args.timeline === freq.value"
                src="@/assets/icons/check-active-green.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/check-null.svg" alt="" />
            </span>
            <span> {{ freq.label }} </span>
          </div>
        </div>

        <easiButtonNew
          @click="
            () => {
              setFrequency = false;
              setAutoPayment = true;
            }
          "
          block
          class="mb-6 mt-8"
        >
          Submit
        </easiButtonNew>
      </div>
    </easiModal>

    <easiModal
      v-if="enterOtp"
      @close="closeModal"
      pixelRounded="40px"
      :isBorder="false"
      size="sm"
    >
      <div class="w-10/12 mx-auto text-center">
        <div class="w-full -mt-8 mb-4">
          <h2
            class="text-primary-deep font-bold text-2xl sm:text-3xl md:text-2xl"
          >
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-2 flex justify-center items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>

          <div
            class="text-secondary justify-center text-sm flex items-center gap-1"
          >
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ minutes }}
            </div>
            :
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ seconds }}
            </div>
          </div>
          <div
            class="text-dark-800 text-sm gap-0 font-medium text-center flex items-center justify-center"
          >
            <span class="-mr-4"> Did not receive OTP?</span>
            <easiButton
              type="button"
              @click="sendOTP('EMAIL')"
              variant="text"
              class="text-xs font-bold px-0"
              :class="
                disabledBtn
                  ? 'cursor-not-allowed text-secondary-disabled'
                  : 'text-secondary'
              "
              :loading="otpLoading"
              :disabled="disabledBtn"
              >Resend</easiButton
            >
          </div>

          <div
            class="text-dark-800 mb-4 text-xs font-medium text-center flex items-center justify-center"
          >
            <easiButton
              type="button"
              @click="sendOTP('SMS')"
              variant="text"
              class="text-xs font-bold px-0"
              :class="
                disabledBtn
                  ? 'cursor-not-allowed text-secondary-disabled'
                  : 'text-secondary'
              "
              :disabled="disabledBtn"
              :loading="resendOtpLoading"
              >Send to phone number instead</easiButton
            >
          </div>
          <div class="w-full">
            <easiButton
              :disabled="otpCode && otpCode.length != 6"
              block
              :loading="confirmOtpLoading"
            >
              Submit
            </easiButton>
          </div>
        </form>
      </div>
    </easiModal>

    <easiModal
      v-if="setAutoPayment"
      @close="setAutoPayment = false"
      pixelRounded="40px"
      :isBorder="false"
    >
      <template v-slot:header
        ><h1 class="text-dark-800 font-medium text-2xl">
          Create Auto-payment
        </h1></template
      >

      <form
        @submit.prevent="
          !edit ? createAutomation('create', true) : updateAutomation('update')
        "
        class="px-3 mt-3 md:px-7"
      >
        <!-- <label
          v-if="edit"
          for="frequency"
          class="text-dark-600 text-sm font-bold"
          >Frequency
        </label> -->

        <easiSelectInput2
          v-if="edit"
          required
          class="mt-1 mb-5"
          @update="args.timeline = $event"
          :value="args.timeline"
          placeholder="Frequency"
          :options="[
            {
              label: 'Daily',
              value: 'DAILY',
            },
            {
              label: 'Weekly',
              value: 'WEEKLY',
            },
            {
              label: 'Bi-weekly',
              value: 'BIWEEKLY',
            },
            {
              label: 'Monthly',
              value: 'MONTHLY',
            },
          ]"
          :searchable="false"
          :autoHeight="true"
        />

        <easiEmployeeBonus
          @update="employeeIds = $event"
          :value="employeeIds"
          :options="createEmpList()"
          label="Select Employee"
          :clearAll="clear"
          class="mt-1 mb-3"
          :directorView="false"
        />

        <easiMultiSelectInput
          required
          class="mt-7 mb-9"
          @update="paymentOption = $event"
          label="Select Payment Type"
          :value="paymentOption"
          :options="paymentOptions"
          :searchable="true"
          :clearAll="clear"
        />

        <!-- <label
          v-if="paymentOption.includes('bonus')"
          for="bonus"
          class="text-dark-800 text-sm font-medium"
          >Bonus</label
        > -->

        <easiMultiSelectInput
          v-if="paymentOption.includes('bonus')"
          required
          class="mt-7 mb-9"
          label="Select Bonus"
          @update="bonuses = $event"
          :value="bonuses"
          :options="bonusOptions"
          :searchable="true"
          :clearAll="clear"
        />

        <!-- <label
          v-if="paymentOption.includes('deduction')"
          for="deduction"
          class="text-dark-800 text-sm font-medium"
          >Deduction</label
        > -->

        <easiMultiSelectInput
          v-if="paymentOption.includes('deduction')"
          required
          class="mt-7 mb-9"
          label="Select Deduction"
          @update="deductions = $event"
          :value="deductions"
          :options="deductionOptions"
          :searchable="true"
          :clearAll="clear"
        />

        <!-- <label
          v-if="paymentOption.includes('statutoryDeductions')"
          for="deduction"
          class="text-dark-800 text-sm font-medium"
          >Statutory Deductions</label
        > -->

        <easiMultiSelectInput
          v-if="paymentOption.includes('statutoryDeductions')"
          required
          class="mt-7 mb-9"
          label="Select Statutory Deductions"
          @update="statutories = $event"
          :value="statutories"
          :options="statutoryOptions"
          :searchable="true"
          :clearAll="clear"
        />

        <!-- <label for="date" class="text-dark-600 text-sm font-medium"
          >Select Date
        </label> -->
        <!-- <input
          v-show="!dateString.length"
          @change="format"
          :min="today"
          v-model="dateString"
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
          type="date"
          name="start"
          id="start"
        /> -->

        <datePicker
          v-show="!dateString.length"
          :min="today"
          @update="
            ($event) => {
              format($event);
            }
          "
          :value="dateString"
          placeholder="Select Date"
        />

        <label
          v-if="args.timeline === 'Weekly'"
          for="dayOfTheWeek"
          class="text-dark-600 text-sm font-bold"
          >Select Day Of The Week
        </label>

        <!-- <easiSelectInput
          v-if="args.timeline === 'WEEKLY'"
          required
          class="mt-1 mb-5"
          @update="args.dayOfTheWeek = $event"
          :value="args.dayOfTheWeek"
          :options="[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ]"
          :searchable="false"
          :autoHeight="true"
        /> -->

        <div
          v-show="dateString.length && args.timeline === 'MONTHLY'"
          @click="
            () => {
              dateString = '';
            }
          "
          class="relative mb-6"
        >
          <input
            readonly
            style="height: 48px"
            class="bg-white w-full focus:border-primary border-dark-200 text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block capitalize appearance-none rounded-2xl"
            v-model="dateString"
          />

          <img
            class="absolute right-4 top-4 w-4"
            src="@/assets/icons/calendar-dark.svg"
            alt=""
          />
        </div>

        <div v-if="args.timeline === 'MONTHLY'" class="flex gap-4 w-full">
          <div
            @click="args.includeWeekends = true"
            :class="
              args.includeWeekends
                ? ' bg-highlight border border-primary'
                : 'bg-none border border-outlineGray'
            "
            class="cursor-pointer flex items-center justify-between shadow-lg w-full justify-center gap-2 px-2 md:px-4 py-3 relative rounded-lg text-dark-800"
          >
            <span class="text-xs font-bold md:text-sm">Include Weekend</span>
            <img
              class="mt-0"
              v-show="args.includeWeekends"
              src="@/assets/icons/checked.svg"
              alt=""
            />
            <div
              v-show="!args.includeWeekends"
              class="w-4 h-4 rounded-full border-2 border-outlineGray bg-white"
            ></div>
          </div>

          <div
            @click="args.includeWeekends = false"
            :class="
              !args.includeWeekends
                ? ' bg-highlight border border-primary '
                : 'bg-none border border-outlineGray'
            "
            class="cursor-pointer flex items-center justify-between shadow-lg w-full justify-center gap-2 px-2 md:px-4 py-3 relative rounded-lg text-dark-800"
          >
            <span class="text-xs font-bold md:text-sm">Skip Weekend</span>
            <div
              v-show="args.includeWeekends"
              class="w-4 h-4 rounded-full border-2 border-outlineGray bg-white"
            ></div>
            <img
              class="-mt-1"
              v-show="!args.includeWeekends"
              src="@/assets/icons/checked.svg"
              alt=""
            />
          </div>
        </div>

        <p
          :class="!args.includeWeekends ? 'hidden' : undefined"
          class="md:text-center mt-4 text-dark-800 text-xs md:text-sm font-bold"
        >
          Continue to run payroll if the selected date falls during a weekend
        </p>

        <!-- <label for="payrollBudget" class="text-dark-800 text-sm font-medium"
          >Input Payroll Budget</label
        > -->
        <!-- <easiTextInput
          placeholder="Payroll Budget"
          v-model="args.payrollBudget"
          type="text"
          name="payrollBudget"
          class="mb-4 mt-0"
          :format="true"
          :error="errorRules.payrollBudget"
        /> -->

        <!-- <p for="payment" class="text-dark-600 mt-4 text-sm font-bold">
          Set Currency
        </p> -->
        <easiSelectInput
          required
          class="mt-8 mb-9"
          :currency="true"
          label="Set Currency"
          :full="true"
          :autoHeight="true"
        />

        <div
          v-if="edit"
          class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <div class="w-full">
            <easiButtonNew
              @click="edit = false"
              type="button"
              color="primary"
              variant="outlined"
              class="bg-white w-full"
            >
              <span class="text-sm text-dark-800"> Cancel</span>
            </easiButtonNew>
          </div>
          <div class="w-full">
            <easiButtonNew
              :loading="loading.edit"
              type="submit"
              color="primary"
              class="w-full"
            >
              <span class="text-sm"> Update </span>
            </easiButtonNew>
          </div>
        </div>
        <!-- ////// Add Buttons ///// -->
        <div
          v-else
          class="w-full my-4 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <div class="w-full">
            <easiButtonNew
              type="button"
              @click="save"
              color="primary"
              variant="outlined"
              class="w-full"
              :class="loading ? 'pointer-events-none' : ''"
            >
              <span class="text-sm"> Save And Add More </span>
            </easiButtonNew>
          </div>

          <div class="w-full">
            <easiButtonNew type="submit" color="primary" class="w-full">
              <span class="text-sm"> Submit </span>
            </easiButtonNew>
          </div>
        </div>
      </form>
    </easiModal>

    <!--Preview Automation start-->

    <easiModal
      v-if="preview"
      @close="preview = false"
      pixelRounded="40px"
      :isBorder="false"
    >
      <template v-slot:header>
        <h1 class="capitalize md:text-2xl text-xl font-medium text-headerText">
          Preview Automation
        </h1>
      </template>

      <div class="flex flex-col gap-6 w-11/12 mx-auto mt-3">
        <div style="border-radius: 16px" class="bg-mint w-full px-3 py-4">
          <p style="color: #191919" class="text-sm font-bold">
            Total payroll budget
          </p>
          <p style="color: #191919" class="text-lg font-bold mt-2">
            {{ formatAmount(15630780.9) }}
          </p>
        </div>

        <div class="flex gap-5">
          <div
            style="background-color: #f8f8f8; border-radius: 8px; padding: 8px"
            class="border border-newAsh flex items-center w-full justify-between"
          >
            <div class="flex flex-col gap-4">
              <p class="text-newGray">Total Employees</p>
              <p class="text-dark-800 font-bold">200</p>
            </div>

            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.16699L6.83333 7.00033L1 12.8337"
                stroke="#8D8E8D"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            style="background-color: #f8f8f8; border-radius: 8px"
            class="border border-newAsh flex items-center w-full justify-between px-4 py-3"
          >
            <div class="flex flex-col gap-4">
              <p class="text-newGray">Currency</p>
              <p class="text-dark-800 font-medium">NGN</p>
            </div>

            <div class="w-8 h-8 rounded-xl">
              <img
                class="object-contain"
                :src="
                  countries && countries.nigeria && countries['nigeria'].flag
                "
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="flex items-center w-full gap-4">
          <div class="w-full">
            <easiButton
              class="w-full flex items-center justify-center gap-3"
              variant="outlined"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.75 12L7.75 12"
                  stroke="#11453B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.75 16L7.75 12L11.75 8"
                  stroke="#11453B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span class="inline-block">Back to edit</span></easiButton
            >
          </div>
          <div class="w-full">
            <easiButton class="w-full">Submit</easiButton>
          </div>
        </div>
      </div>
    </easiModal>

    <!--Preview Automation end-->
    <easiModal
      v-if="setActiveStatus"
      @close="setActiveStatus = false"
      :isBorder="false"
      size="sm"
      pixelRounded="40px"
    >
      <!-- <template v-slot:header>
        <h2 class="text-dark-800 font-medium text-lg md:text-3xl text-center">
          Are you sure you want to {{ action }} this payroll automation?
        </h2>
      </template> -->

      <div class="flex flex-col justify-center gap-7 items-center mb-4">
        <img
          v-if="action === 'pause'"
          class="w-24"
          src="@/assets/icons/stop-icon.svg"
          alt=""
        />
        <img v-else class="w-24" src="@/assets/icons/resume-icon.svg" alt="" />
        <h2
          class="text-dark-800 font-medium text-lg mx-auto justify-self-center md:text-2xl text-center"
        >
          Are you sure you want <br />
          to {{ action }} this payroll automation?
        </h2>

        <div
          class="px-8 w-full mt-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="setActiveStatus = false"
            class="w-full font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
            >Cancel</span
          >
          <div class="w-full">
            <easiButtonNew
              @click="updateAutomation('status')"
              class="w-full font-medium focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
            >
              Yes
            </easiButtonNew>
          </div>
        </div>
      </div>
    </easiModal>

    <easiWarn
      v-if="deleteWarning"
      @close="deleteWarning = false"
      size="sm"
      pixelRounded="40px"
    >
      <template v-slot:message>
        <h1 class="w-full text-base md:text-2xl">
          Are you sure you want to delete this payroll automation?
        </h1>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4 overflow-hidden w-full">
          <div class="flex justify-center items-center md:w-full gap-x-4 mt-6">
            <easiButtonNew
              class="w-32 md:w-40"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButtonNew
            >
            <easiButtonNew class="w-32 md:w-40" @click="deleteAutomation"
              >Yes</easiButtonNew
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <!-- NEXT -->
    <easiModal
      pixelRounded="40px"
      :isBorder="false"
      v-if="customizePayroll"
      @close="customizePayroll = false"
    >
      <div class="px-4 md:px-8">
        <div>
          <h2
            class="text-secondary mb-2 text-center md:text-left font-bold text-2xl sm:text-3xl md:text-3xl capitalize"
          >
            Customize Payroll
          </h2>
          <p class="text-xs md:text-base text-center md:text-left font-medium">
            Specific payroll requests based on your business and budget needs
          </p>
        </div>
        <form
          @submit.prevent="createAutomation((action = 'customize'), true)"
          autocomplete="on"
          class="mb-5 flex flex-col w-full text-left"
        >
          <easiTextInput
            placeholder="Business Name"
            type="text"
            name="businessName"
            class="mb-5 mt-1"
            :error="errorRules.businessName"
          />

          <!-- <label for="companySize" class="text-dark-600 text-sm font-bold"
            >Select Number Of Employees</label
          > -->

          <easiSelectInput
            class="mb-1 mt-7"
            @update="companySize = $event"
            :value="companySize"
            label="Select Number Of Employees"
            :options="['1-20', '21-50', '51-100', '100 Above']"
            :error="errorRules.companySize"
          />

          <label for="email" class="mt-4 text-dark-800 text-sm font-medium"
            >Please Select Employee
            <span class="text-xs text-error tracking-wide"> *</span></label
          >
          <easiEmployeeBonus
            @update="employeeIds = $event"
            :value="employeeIds"
            :options="createEmpList()"
            class="mt-1 mb-3"
          />

          <!-- <label for="payment" class="text-dark-600 text-sm font-bold"
            >Select Payment
          </label> -->
          <easiMultiSelectInput
            required
            class="mt-1 mb-3"
            @update="paymentOption = $event"
            label="Select payment"
            :value="paymentOption"
            :options="paymentOptions"
            :searchable="true"
          />

          <label
            v-if="paymentOption.includes('bonus')"
            for="bonus"
            class="text-dark-800 text-sm font-medium"
            >Bonus</label
          >

          <bonusSelection
            v-if="paymentOption.includes('bonus')"
            required
            class="mb-5"
            @update="bonuses = $event"
            :value="bonuses"
            :options="bonusOptions"
            :searchable="true"
          />

          <label
            v-if="paymentOption.includes('deduction')"
            for="deduction"
            class="text-dark-800 text-sm font-medium"
            >Deduction</label
          >

          <bonusSelection
            v-if="paymentOption.includes('deduction')"
            required
            class="mt-1 mb-5"
            @update="deductions = $event"
            :value="deductions"
            :options="deductionOptions"
            :searchable="true"
          />

          <label
            v-if="paymentOption.includes('statutoryDeductions')"
            for="deduction"
            class="text-dark-800 text-sm font-medium"
            >Statutory Deductions</label
          >

          <bonusSelection
            v-if="paymentOption.includes('statutoryDeductions')"
            required
            class="mt-1 mb-5"
            @update="statutories = $event"
            :value="statutories"
            :options="statutoryOptions"
            :searchable="true"
          />

          <!-- <label for="frequency" class="text-dark-600 text-sm font-bold"
            >Select Payroll Frequency (Optional)
          </label> -->

          <easiSelectInput
            required
            class="mb-5"
            @update="args.timeline = $event"
            :value="args.timeline"
            :options="['Weekly', 'Bi-weekly', 'Monthly']"
            label="Select Payroll Frequency (Optional)"
            :searchable="false"
            :autoHeight="true"
          />

          <!-- <label for="payrollBudget" class="text-dark-800 text-sm font-medium"
            >Input Payroll Budget</label
          > -->
          <easiTextInput
            placeholder="Payroll Budget"
            v-model="args.payrollBudget"
            type="text"
            name="payrollBudget"
            class="mb-4 mt-1"
            :format="true"
            :error="errorRules.payrollBudget"
          />

          <!-- <div
            class="flex items-center justify-center md:justify-start gap-4 mt-2 mb-5"
          >
            <h4 class="text-xl md:text-3xl text-priBorder">
              Customize My Payroll
            </h4>
            <img
              v-show="!customize"
              @click="customize = !customize"
              src="@/assets/icons/blue-uncheck.svg"
              alt=""
            />
            <img
              v-show="customize"
              @click="customize = !customize"
              src="@/assets/icons/blue-check.svg"
              alt=""
            />
          </div> -->

          <easiButtonNew block color="primary" class="mt-4">
            Customize
          </easiButtonNew>
        </form>
      </div>
    </easiModal>

    <easiModal
      v-if="contactModal"
      @close="contactModal = false"
      :isBorder="false"
    >
      <h3 class="text-center text-lg md:text-2xl px-7 md:px-16 text-dark-800">
        <span
          class="md:text-3xl text-xl md:text-center text-left text-secondary font-bold"
        >
          Thank you for your request.</span
        >
        <br />
        A member of our team will contact you shortly.
      </h3>

      <div class="flex justify-center mb-3 mt-8">
        <img src="@/assets/img/customer-support.png" alt="" />
      </div>

      <hr class="border-0.5 border-dividerColor" />
      <div class="px-4 sm:px-10 lg:px-6 xl:px-8 my-4">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-lg text-white"
          @click="window.location.reload()"
        >
          Back To Payroll Settings
        </button>
      </div>
    </easiModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>

    <easiLoading v-if="otpLoading" />

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted, shallowRef } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import datePicker from "@/components/global/DatePicker.vue";
import SwitchInput from "@/components/global/InputSwitch.vue";

import InputSwitch from "primevue/inputswitch";

//// View Components ////
import Profile from "@/components/Settings/Profile/Profile.vue";
import AccountInfo from "@/components/Settings/Profile/AccountInfo.vue";

import editAutomation from "@/assets/icons/automation-edit.svg";
import pauseAutomation from "@/assets/icons/automation-pause.svg";
import resumeAutomation from "@/assets/icons/automation-resume.svg";
import automationDelete from "@/assets/icons/automation-delete.svg";

import naira from "@/assets/icons/naira-icon.svg";
import dollar from "@/assets/icons/dollar-icon.svg";
import pound from "@/assets/icons/pound-icon.svg";

import nigeria from "@/assets/icons/nigeria-flag.svg";
import usa from "@/assets/icons/usa-flag.svg";
import britain from "@/assets/icons/britain-flag.svg";

const toast = useToast();
const store = useDataStore();
const { mutate, query } = useDataStore();
const { listEmployees, getAllEmployees } = storeToRefs(store);

const fullCompanyData = computed(() => store.getCompanyDetails);
const bonusDeduction = computed(() => store.getBonusAndDeduction);
const allEmployees = computed(() => getAllEmployees.value.data);
const automations = computed(() => store.getPayrollAutomation);

const { processDate, resetObj, formatAmount } = helperFunctions;

const icons = {
  delete: automationDelete,
  edit: editAutomation,
  pause: pauseAutomation,
  resume: resumeAutomation,
};

const preview = ref(false);
const loading = ref(false);
const action = ref("pause");
const deleteWarning = ref(false);
const companySize = ref("");

const minutes = ref(2);
const seconds = ref("00");
const timer = ref(120);
const disabledBtn = ref(false);
const checkedBool = ref(false);

const getClass = (type, obj) => {
  if (obj.paused == true && type === "pause") {
    return "hidden";
  }
  if (obj.paused == false && type === "resume") {
    return "hidden";
  }
  return "flex";
};
function startTimer() {
  disabledBtn.value = true;
  const interval = setInterval(function () {
    minutes.value = parseInt(timer.value / 60, 10);
    seconds.value = parseInt(timer.value % 60, 10);

    minutes.value = minutes.value < 10 ? "0" + minutes.value : minutes.value;
    seconds.value = seconds.value < 10 ? "0" + seconds.value : seconds.value;

    if (--timer.value < 0) {
      timer.value = 120;
      disabledBtn.value = false;
      clearInterval(interval);
    }
  }, 1000);
}
async function queryAutomations() {
  loading.value = true;

  try {
    await query({
      endpoint: "ListPayrollAutomations",
      service: "PAYROLL",
      storeKey: "payrollAutomations",
    });
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
}
queryAutomations();

function getDateFromDay(d) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let currentDay = new Date().getDate();

  let payrollMonth;
  if (Number(currentDay) > Number(d)) {
    payrollMonth = Number(currentMonth) + 1;
  } else {
    payrollMonth = Number(currentMonth);
  }

  let date = new Date(currentYear, payrollMonth, d);
  const dayInt = date.getDay();
  const dateString = days[dayInt];
  const fullDate = date.toLocaleDateString().split("/");

  const formattedDate = `${fullDate[1]}/${fullDate[0]}/${fullDate[2]}`;
  return {
    formattedDate,
    dateString,
  };
}

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});
onMounted(() => {
  store.$patch({
    profileArr: [],
  });
});
const countries = {
  nigeria: { flag: nigeria, currency: naira, text: "Nigeria Naira (NGN)" },
  usa: { flag: usa, currency: dollar, text: "United States Dollar (USD)" },
  britain: { flag: britain, currency: pound, text: "United Kingdom (GDP)" },
};
const bonuses = ref([]);
const deductions = ref([]);
const statutories = ref([]);

const employeeIds = ref([]);
const payrollDate = ref("");
const payrollAutomationId = ref("");

const clear = ref(false);
const customize = ref(false);
const edit = ref(false);
const successMessage = ref("");
const today = ref("");
today.value = processDate();
const bonusData = ref([]);
const deductionData = ref([]);
const bonusOptions = ref([]);
const deductionOptions = ref([]);

const paymentOption = ref([]);
const bonusNames = ref([]);
const deductionNames = ref([]);
const argsArray = ref([]);
const profileArgsFromStore = computed(() => store.profileData);
const display = shallowRef(Profile);
const show = ref(false);
const activeAuto = ref("");
const dateString = ref("");
const selectedfreq = ref("");
const setFrequency = ref(false);
const enterOtp = ref(false);
const updateSuccess = ref(false);
const setAutoPayment = ref(false);
const setActiveStatus = ref(false);
const customizePayroll = ref(false);
const contactModal = ref(false);
const otpReference = ref("");
const otpCode = ref("");
function checkOTP(otp_input) {
  otpCode.value = otp_input;
}
const storeAction = ref({
  act: "",
  bool: false,
});

function closeModal() {
  if (storeAction.value.act === "switch") {
    checkedBool.value = !checkedBool.value;
  }
  enterOtp.value = false;
}
const errorRules = reactive({
  firstName: false,
  payrollBudget: false,
  email: false,
  phoneNumber: false,
  companyName: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
  companySize: false,
});

const otpLoading = ref(false);
const resendOtpLoading = ref(false);
const confirmOtpLoading = ref(false);
const loadingProgress = ref(false);
const args = reactive({
  includeWeekends: true,
  payrollBudget: 0,
  payrollDate: null,
  paymentOptions: {
    salary: false,
    bonus: false,
    statutory: false,
    deduction: false,
  },
  bonuses: [],
  deductions: [],
  statutories: [],
  timeline: "MONTHLY",
  employeeIds: [],
  paused: false,
});

const queryEmployees = async () => {
  //   loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};

queryEmployees();

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

// const loading = ref({
//   save: false,

//   add: false,
//   edit: false,
// });

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;

    bonusOptions.value = bonusData.value.map((bd) => {
      bonusNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    deductionOptions.value = deductionData.value.map((bd) => {
      deductionNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });
  } catch (err) {
    console.log(err);
  }
};

queryBonus();
queryDeduction();

function checkPin(otp_input) {
  args.pin = otp_input;
}

const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
function getIndexByMonth(month) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // return months[index];
  return months.indexOf(month);
}

function getMonthByIndex(index) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index];
}

const statutoryOptions = ref([
  {
    label: "PAYE",
    value: "PAYE",
  },
  {
    label: "Pension",
    value: "Pension",
  },
  {
    label: "NHF",
    value: "NHF",
  },
  {
    label: "NSITF",
    value: "NSITF",
  },
  {
    label: "ITF",
    value: "ITF",
  },
  {
    label: "WHT",
    value: "WHT",
  },
  {
    label: "HMO",
    value: "HMO",
  },
  {
    label: "Life Insurance",
    value: "Group_Life_Insurance",
  },
  {
    label: "Life Assurance",
    value: "Employee_Life_Assurance",
  },
  {
    label: "Business Expense",
    value: "Business_Expense",
  },
  {
    label: "Gratuity",
    value: "Gratuity",
  },
]);

const frequency = ref([
  // {
  //   label: "Weekly",
  //   value: "WEEKLY",
  // },
  // {
  //   label: "Bi-weekly",
  //   value: "BIWEEKLY",
  // },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
]);

const payroll = ref([
  {
    day: "Thursday",
    date: "14/09/2023",
    selected: ["PAYE", "Net pay"],
    active: true,
  },
  {
    day: "Wednesday",
    date: "26/10/2023",
    selected: ["PAYE", "Net pay", "NSITF", "ITF"],
    active: false,
  },
  {
    day: "Friday",
    date: "29/11/2023",
    selected: ["PAYE", "Net pay", "NSITF", "ITF", "WHT"],
    active: true,
  },
]);

const mapIdToName = () => {
  let selectedBonus, selectedDeductions;
  if (bonuses.value.length) {
    const bon = bonusData.value.map((bo) => {
      if (bonuses.value.includes(bo._id)) {
        return bo.name;
      }
    });
    selectedBonus = bon.filter((item) => item != null);
  }

  if (deductions.value.length) {
    const dedu = deductionData.value.map((ded) => {
      if (deductions.value.includes(ded._id)) {
        return ded.name;
      }
    });
    selectedDeductions = dedu.filter((item) => item != null);
  }

  return {
    selectedBonus,
    selectedDeductions,
  };
};

const paymentOptions = ref([
  {
    label: "Net Salary",
    value: "netSalary",
  },
  {
    label: "Bonus",
    value: "bonus",
  },
  {
    label: "Statutory Deductions",
    value: "statutoryDeductions",
  },
  {
    label: "Deductions",
    value: "deduction",
  },
]);

const pages = reactive([
  {
    title: "Payroll Automation",
    value: "automate",
    // component: Profile,
    active: true,
    live: "view",
  },
  {
    title: "Customise payroll",
    value: "customize",
    // component: AccountInfo,
    active: false,
    live: "view",
  },
]);
function position(dt) {
  let day = dt.charAt(1);
  let preceeding = dt.charAt(0);
  if (preceeding !== "1" && day === "1")
    return `${Number(dt)}st of every month`;
  if (preceeding !== "1" && day === "2")
    return `${Number(dt)}nd of every month`;
  if (preceeding !== "1" && day === "3")
    return `${Number(dt)}rd of every month`;
  return `${Number(dt)}th of every month`;
}

const companyData = computed(() =>
  store.getCompanyAdmin ? store.getCompanyAdmin : {}
);
function format(e) {
  let date = e.slice(8, 10);

  payrollDate.value = Number(date);

  dateString.value = position(String(date));
}
async function sendOTP(via) {
  const admin = fullCompanyData.value.companyAdmin;
  //validate form field
  const payload = {
    channel: via && typeof via === "string" ? via : "EMAIL",
    value:
      via && typeof via === "string" && via === "SMS"
        ? admin.email //changed from phoneNumber to email but keeping the code in case it's later changed to phone number
        : admin.email,

    type: "PAYROLL_AUTOMATION",
  };

  otpLoading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      startTimer();
      enterOtp.value = true;
      otpLoading.value = false;
      otpReference.value = res.data.reference;
      // toast.success("OTP")
      // window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    console.log(e);
    otpLoading.value = false;
  }
}

async function confirmOtp() {
  setActiveStatus.value = false;
  deleteWarning.value = false;
  setAutoPayment.value = false;
  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    confirmOtpLoading.value = true;
    loadingProgress.value = true;
    const admin =
      companyData.value && companyData.value.companyAdmin
        ? companyData.value.companyAdmin.email
        : fullCompanyData.value.companyAdmin.email;

    const payload = {
      reference: otpReference.value,
      code: otpCode.value,
      value: admin,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;
        loadingProgress.value = false;

        //Register user
        if (
          storeAction.value.act === "create" ||
          storeAction.value.act === "customize"
        ) {
          await createAutomation(storeAction.value.act, storeAction.value.bool);
        }
        if (
          storeAction.value.act === "update" ||
          storeAction.value.act === "status"
        ) {
          await updateAutomation(storeAction.value.act);
        }

        if (storeAction.value.act === "delete") {
          await deleteAutomation();
        }
        if (storeAction.value.act === "switch") {
          await switchAutomation();
        }
        otpCode.value = "";
        enterOtp.value = false;
      }
    } catch (e) {
      loadingProgress.value = false;
      confirmOtpLoading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      confirmOtpLoading.value = false;
    }
  }
}
async function switchAutomation() {
  storeAction.value.act = "switch";
  if (!otpCode.value.length) {
    setActiveStatus.value = false;
    await sendOTP("EMAIL");
    return;
  }
  try {
    const res = await mutate({
      endpoint: "AutomationSwitch",
      data: {
        input: { action: checkedBool.value ? "ON" : "OFF" },
        otpRef: otpReference.value,
      },
      service: "PAYROLL",
    });
    if (res.success) {
      await queryAutomations();
      successMessage.value = res.message;
      setAutoPayment.value = false;
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    }
  } catch (e) {
    console.log(e);
  }
}
async function addMore(arg) {
  let obj = {
    includeWeekends: true,
    payrollBudget: 0,
    payrollDate: null,
    paymentOptions: {
      salary: false,
      bonus: false,
      statutory: false,
      deduction: false,
    },
    bonuses: [],
    deductions: [],
    statutories: [],
    timeline: "MONTHLY",
    employeeIds: [],
    paused: false,
  };
  args.payrollBudget = Number(args.payrollBudget);
  obj.payrollDate = Number(payrollDate.value);
  args.payrollDate = Number(payrollDate.value);

  //Objects are handled differently because of object reference

  obj.employeeIds = employeeIds.value.map((id) => id);
  obj.statutories = statutories.value.map((id) => id);
  obj.bonuses =
    (mapIdToName().selectedBonus &&
      mapIdToName().selectedBonus.map((id) => id)) ||
    [];
  obj.deductions =
    (mapIdToName().selectedDeductions &&
      mapIdToName().selectedDeductions.map((id) => id)) ||
    [];

  console.log(employeeIds.value.length, "IDSs", obj.employeeIds.length);

  paymentOption.value.includes("netSalary")
    ? (obj.paymentOptions.salary = true)
    : (obj.paymentOptions.salary = false);
  paymentOption.value.includes("bonus")
    ? (obj.paymentOptions.bonus = true)
    : (obj.paymentOptions.bonus = false);
  paymentOption.value.includes("deduction")
    ? (obj.paymentOptions.deduction = true)
    : (obj.paymentOptions.deduction = false);
  paymentOption.value.includes("statutoryDeductions")
    ? (obj.paymentOptions.statutory = true)
    : (obj.paymentOptions.statutory = false);

  Object.assign({}, args.paymentOptions, obj.paymentOptions);
  // End of object handler

  args.payrollBudget = Number(args.payrollBudget);

  const keys = Object.keys(obj).map((k) => {
    if (typeof obj[k] != "object" && obj[k] instanceof Array === false) {
      if (obj[k] != null) {
        obj[k] = args[k];
      }
    }
    return k;
  });
  const newObj = Object.assign({}, obj);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
  obj.payrollBudget = 0;
  obj.payrollDate = 0;
  args.payrollBudget = 0;
  // args.payrollDate = 0;
  bonuses.value = bonuses.value.splice(0);
  deductions.value = deductions.value.splice(0);
  statutories.value = statutories.value.splice(0);
  paymentOption.value = paymentOption.value.splice(0);
  employeeIds.value = employeeIds.value.splice(0);

  paymentOption.value = [];

  clear.value = true;
  dateString.value = "";

  Object.assign({}, args, resetObj(obj, "obj"));
}

async function save() {
  addMore(args);
  await createAutomation("create", false);

  // addMore(args);
  // Object.assign(args, resetObj(args, "args"));
}

async function updateArgs(auto, payrollAction) {
  payrollAutomationId.value = auto._id;

  if (payrollAction !== "delete") {
    employeeIds.value = auto.employeeIds;
    let opt = {
      salary: "netSalary",
      statutory: "statutoryDeductions",
      bonus: "bonus",
      deduction: "deduction",
    };

    for (let i of Object.keys(args)) {
      args[i] = auto[i];
    }
    for (let k of Object.keys(opt)) {
      if (auto.paymentOptions[k]) {
        paymentOption.value.push(opt[k]);
      }
    }

    const bon = bonusData.value.map((bo) => {
      if (auto.bonuses.includes(bo.name)) {
        return bo._id;
      }
    });
    bonuses.value = bon.filter((item) => item != null);

    const dedu = deductionData.value.map((ded) => {
      if (auto.deductions.includes(ded.name)) {
        return ded._id;
      }
    });
    deductions.value = dedu.filter((item) => item != null);

    statutories.value = auto.statutories;
    args.payrollDate = auto.payrollDate;
    args.includeWeekends = auto.includeWeekends;

    args.payrollBudget = auto.payrollBudget;
    dateString.value = getDateFromDay(auto.payrollDate).formattedDate;

    if (payrollAction === "update" || payrollAction === "edit") {
      edit.value = true;
      setAutoPayment.value = true;
      args.paused = auto.paused;
    } else if (payrollAction === "pause") {
      args.paused = true;
      action.value = "pause";
      setActiveStatus.value = true;
      console.log(args, "PAUSE");
    } else if (payrollAction === "resume") {
      args.paused = false;
      action.value = "resume";
      setActiveStatus.value = true;
    }
  } else if (payrollAction === "delete") {
    deleteWarning.value = true;
  }
}

const createAutomation = async (action = "create", final) => {
  storeAction.value.act = action;
  storeAction.value.bool = final;
  if (!otpCode.value.length) {
    setAutoPayment.value = false;
    await sendOTP("EMAIL");
    return;
  }
  loading.value = true;

  if (final) {
    addMore(args);
  }

  try {
    let res = await mutate({
      endpoint: "CreatePayrollAutomation",
      data: { input: profileArgsFromStore.value, otpRef: otpReference.value },
      service: "PAYROLL",
    });
    if (res.success) {
      await queryAutomations();
      loading.value = false;
      if (final) {
        if (action === "customize") {
          customizePayroll.value = false;
          contactModal.value = true;
        } else {
          successMessage.value = "Payroll automation successful";
          setAutoPayment.value = false;
          updateSuccess.value = true;

          setTimeout(() => {
            updateSuccess.value = false;
          }, 1000);
        }
      } else {
        toast.success("Details saved!");
      }
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

const updateAutomation = async (action) => {
  storeAction.value.act = action;
  if (!otpCode.value.length) {
    setActiveStatus.value = false;
    await sendOTP("EMAIL");
    return;
  }
  loading.value = true;
  if (action !== "status") {
    addMore(args);
    // console.log(profileArgsFromStore.value[0]);
  }

  // return true

  try {
    let res = await mutate({
      endpoint: "UpdatePayrollAutomation",
      data: {
        payrollAutomationId: payrollAutomationId.value,
        input: action === "status" ? args : profileArgsFromStore.value[0],
        otpRef: otpReference.value,
      },
      service: "PAYROLL",
    });

    if (res.success) {
      loading.value = false;
      successMessage.value = "Payroll automation updated successfully";
      setAutoPayment.value = false;
      setActiveStatus.value = false;
      updateSuccess.value = true;

      await queryAutomations();

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

const deleteAutomation = async () => {
  storeAction.value.act = "delete";
  if (!otpCode.value.length) {
    deleteWarning.value = false;
    await sendOTP("EMAIL");
    return;
  }
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "DeletePayrollAutomation",
      data: {
        payrollAutomationId: payrollAutomationId.value,
        otpRef: otpReference.value,
      },
      service: "PAYROLL",
    });
    if (res.success) {
      await queryAutomations();
      loading.value = false;
      successMessage.value = "Payroll automation deleted successfully";
      deleteWarning.value = false;
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

const switchAsync = async (e) => {
  await switchAutomation();
  checkedBool.value = e;
};

const switchView = (index) => {
  if (index === 1) {
    customizePayroll.value = true;
    return;
  }
  display.value = pages[index].value;

  // store.$patch({
  //   pageIndex: index,
  // });

  pages.forEach((el) => {
    el.active = false;
  });

  pages[index].active = true;
};

switchView(0);

function getPaymentOptions(obj) {
  // console.log(obj, "OBJ");
  let arr = [];
  let opt = {
    salary: "Net Pay",
    statutory: "Statutory Deductions",
    bonus: "Bonus",
    deduction: "Deductions",
  };

  for (let k of Object.keys(opt)) {
    if (obj[k]) {
      arr.push(opt[k]);
    }
  }
  return arr;
}

// const switchColor = computed(() => {
//   return checkedBool.value === true
//     ? "rgba(33, 150, 83, 1)"
//     : "rgba(175, 175, 175, 1)";
// });

onMounted(() => {
  if (automations.value && automations.value.length) {
    const foundInactive = automations.value.find((aut) => aut.paused === true);
    console.log(automations.value);
    if (foundInactive) {
      checkedBool.value = false;
    } else {
      checkedBool.value = true;
    }
  } else {
    checkedBool.value = false;
  }
});
</script>

<style>
.gradient {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}

/* .active-color {
  background: rgba(33, 150, 83, 1);
} */

.p-inputswitch-slider {
  background: var(--switchColor) !important;
}
</style>
