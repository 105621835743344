<template>
  <div class="w-full mt-5 relative">
    <span
      v-if="inputValue !== placeholder"
      class="absolute pointer-events-none left-5 py-0 -top-3 px-5 bg-white text-xs text-newGray capitalize"
    >
      {{ placeholder }}
    </span>
    <input
      class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-2xl py-2 px-4 pl-12 block appearance-none rounded-2xl mt-1"
      :class="compliance ? 'w-52 mb-2' : 'w-auto mb-5'"
      :type="inputType"
      :value="inputValue"
      @mouseover="hidePlaceholder"
      :max="inputType === 'date' ? max : undefined"
      :min="inputType === 'date' ? min : undefined"
      @blur="showPlaceholder"
      @input="updateValue"
    />
    <span class="custom-icon" v-if="inputValue === placeholder">
      <img src="@/assets/icons/calendar-new.svg" alt="" />
    </span>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";

const props = defineProps({
  placeholder: {
    type: String,
  },
  max: {
    type: String,
  },
  min: {
    type: String,
  },
  value: {
    type: String,
    default: "",
  },
  compliance: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update"]);
const watchProp = computed(() => props.placeholder);
const watchValue = computed(() => (props.value ? props.value : ""));
const inputType = ref("text");
const inputValue = ref("");

inputValue.value = watchValue.value;

watch(
  watchProp,
  watchValue,
  () => {
    inputValue.value = props.value ? props.value : props.placeholder;
  },
  { immediate: true }
);

const showPlaceholder = () => {
  if (inputValue.value === "") {
    inputType.value = "text";
    inputValue.value = props.value ? props.value : props.placeholder;
  }
};

const hidePlaceholder = () => {
  if (inputValue.value === props.placeholder) {
    inputType.value = "date";
    inputValue.value = props.value;
  }
};

const updateValue = (event) => {
  inputValue.value = event.target.value;
  emit("update", inputValue.value);
};

onMounted(() => {
  inputValue.value = props.value ? props.value : props.placeholder;
});
</script>

<style scoped>
.custom-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 20px;
  opacity: 0.7;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  content: ""; /* FontAwesome calendar icon */
  font-family: "FontAwesome";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #333;
  background-image: url("../../assets/icons/calendar-new.svg");
  background-size: cover;
}
</style>
